// import VIDEO from "../assets/video/Loop UNIQUE.mp4";
// import VIDEOWEB from "../assets/video/Loop UNIQUE.webm";

export const getDataHero = (option) => {
  let heroInfo = {};
  switch (option) {
    case 'guitars':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725576887/guitars-hero_yebqc5.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'GUITARRAS',
        usTitle: 'GUITARS',
        logo: false,
        isVideo: false,
      };
      break;
    case 'acoustic':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725557420/ea-hero_ish6kt.png',
        position: 'center',
        size: 'cover',
        esTitle: 'ACUSTICA',
        usTitle: 'ACOUSTIC',
        logo: false,
        isVideo: false,
      };
      break;
    case 'acoustic-electric':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725557420/ea-hero_ish6kt.png',
        position: 'center',
        size: 'cover',
        esTitle: 'ELECTRO-ACUSTICA',
        usTitle: 'ACOUSTIC-ELECTRIC',
        logo: false,
        isVideo: false,
      };
      break;
    case 'bass':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725576883/basses-hero_otdvzs.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'BAJOS',
        usTitle: 'BASSES',
        logo: false,
        isVideo: false,
      };
      break;
    case 'bassUnique':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725576883/basses-hero_otdvzs.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'SERIES',
        usTitle: 'SERIES',
        logo: true,
        logoLang: false,
        logoUrl: 'https://i.ibb.co/BfL26yz/unique-bg.png',
      };
      break;
    case 'ukuleleUnique':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725576880/ukulele-hero_noo1jw.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'SERIES',
        usTitle: 'SERIES',
        logo: true,
        logoLang: false,
        logoUrl: 'https://i.ibb.co/BfL26yz/unique-bg.png',
      };
      break;
    case 'ukuleles':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725576880/ukulele-hero_noo1jw.jpg',
        position: '0 97%',
        size: 'contain',
        esTitle: 'UKULELES',
        usTitle: 'UKULELES',
        logo: false,
        isVideo: false,
      };
      break;
    case 'ukulelesBseries':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725577296/ukubseries_mfsau5.jpg',
        position: 'center 50%',
        size: 'cover',
        esTitle: 'B-SERIES',
        usTitle: 'B-SERIES',
        logo: false,
        isVideo: false,
      };
      break;
    case 'stringsGuitar':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725577420/string-guitars-hero_ei0pcu.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'GUITARRA',
        usTitle: 'GUITAR',
        logo: false,
        isVideo: false,
      };
      break;
    case 'stringsBass':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725577522/strings-bass-hero_wfziyv.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'BAJO',
        usTitle: 'BASS',
        logo: false,
        isVideo: false,
      };
      break;
    case 'stringsUkulele':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725577575/strings-uku-hero_qix0wt.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'UKULELE',
        usTitle: 'UKULELE',
        logo: false,
        isVideo: false,
      };
      break;
    case 'packs':
      heroInfo = {
        url: 'https://i.ibb.co/7kpBHSc/packs.png',
        position: 'center',
        size: 'cover',
        esTitle: 'PAQUETES',
        usTitle: 'PACKS',
        logo: false,
        isVideo: false,
      };
      break;
    case 'bassBseries':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725577726/hero-bass-bserie_ng9irr.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'B-SERIES',
        usTitle: 'B-SERIES',
        logo: false,
        isVideo: false,
      };
      break;
    // case 'unique':
    //   heroInfo = {
    //     url: 'https://i.ibb.co/3vQqkVs/ninoHero.png',
    //     position: '20% 80%',
    //     size: 'cover',
    //     esTitle: 'UNIQUE',
    //     usTitle: 'UNIQUE',
    //     logo: false,
    //     isVideo: false,
    //   };
    //   break;
    case 'b-series':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725577831/hero-guitars-bseries_ivngoj.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'B-SERIES',
        usTitle: 'B-SERIES',
        logo: false,
        isVideo: false,
      };
      break;
    case 'Master IV':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725639090/unique-master_olvidk.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'MASTER IV',
        usTitle: 'MASTER IV',
        logo: false,
        isVideo: false,
      };
      break;
    case 'Master V':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725639090/unique-master_olvidk.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'MASTER V',
        usTitle: 'MASTER V',
        logo: false,
        isVideo: false,
      };
      break;
    case 'Leggend':
      heroInfo = {
        url: 'https://res.cloudinary.com/dugnvdqaw/image/upload/v1725390780/leggend-2_ixjpvv.jpg',
        position: '20% 40%',
        size: 'cover',
        esTitle: 'LEGGEND',
        usTitle: 'LEGGEND',
        logo: false,
        isVideo: false,
      };
      break;
    case 'Cosmos II':
      heroInfo = {
        url: 'https://res.cloudinary.com/de17sq7ft/image/upload/v1725578054/cosmos-II-hero-min_diyyrz.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'COSMOS II',
        usTitle: 'COSMOS II',
        logo: false,
        isVideo: false,
      };
      break;
    case 'Cosmos':
      heroInfo = {
        url: 'https://res.cloudinary.com/dugnvdqaw/image/upload/v1725407680/cosmos-hero-min_tewtjs.jpg',
        position: 'center',
        size: 'cover',
        esTitle: 'COSMOS',
        usTitle: 'COSMOS',
        logo: false,
        isVideo: false,
      };
      break;
    default:
      heroInfo = {
        // url: VIDEO,
        // urlWp: VIDEOWEB,
        url: 'https://res.cloudinary.com/dugnvdqaw/image/upload/v1725389542/unique-series_lgcwvl.jpg',
        position: 'center 45%',
        size: 'cover',
        esTitle: 'SERIES',
        usTitle: 'SERIES',
        logo: true,
        logoLang: false,
        logoUrl: 'https://i.ibb.co/BfL26yz/unique-bg.png',
        isVideo: true,
      };
      break;
  }
  return heroInfo;
};

// {
//   "content": false,
//   "labelEs": "Tienda",
//   "labelUs": "Shop",
//   "lang": true,
//   "redirectTo": "https://hermesmusic.com/collections/babilon",
//   "redirectToEs": "https://hermesmusicclub.mx/collections/vendors?q=BABILON"
// }
