import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProductsByCategoryAndProduct } from '../services/products';

export const useStringsCategory = () => {
  const { category, product } = useParams();
  const [strings, setStrings] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getProductsByCategoryAndProduct(category, product).then((data) => {
      setStrings(data);
      setInterval(() => {
        setLoader(false);
      }, 1500);
    });
  }, [product, category]);

  // const Basics = strings.filter((string) => string.gama === 'Basic');

  const Professionals = strings
    .filter(
      (string) =>
        string.gama === 'Professional Tour' && !string.modelo.includes('PACK')
    )
    .sort((a, b) => a.modelo.localeCompare(b.modelo));

  const Masters = strings
    .filter((string) => string.gama === 'Master Tour')
    .sort((a, b) => a.modelo.localeCompare(b.modelo));

  const Premiums = strings
    .filter((string) => string.gama === 'Premium Tour')
    .sort((a, b) => a.modelo.localeCompare(b.modelo));

  return {
    // Basics,
    Professionals,
    Masters,
    Premiums,
    loader,
  };
};
